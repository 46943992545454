// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-go-to-market-js": () => import("./../../../src/pages/go-to-market.js" /* webpackChunkName: "component---src-pages-go-to-market-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-key-performance-indicators-js": () => import("./../../../src/pages/key-performance-indicators.js" /* webpackChunkName: "component---src-pages-key-performance-indicators-js" */),
  "component---src-pages-marketing-strategy-js": () => import("./../../../src/pages/marketing-strategy.js" /* webpackChunkName: "component---src-pages-marketing-strategy-js" */),
  "component---src-pages-sales-performance-js": () => import("./../../../src/pages/sales-performance.js" /* webpackChunkName: "component---src-pages-sales-performance-js" */),
  "component---src-pages-sales-phases-js": () => import("./../../../src/pages/sales-phases.js" /* webpackChunkName: "component---src-pages-sales-phases-js" */),
  "component---src-pages-strategy-js": () => import("./../../../src/pages/strategy.js" /* webpackChunkName: "component---src-pages-strategy-js" */)
}

